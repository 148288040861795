import React, { Fragment } from "react";
import { Layout, SEO, ShopProductsList } from "src/components";
import { HeroTemplate } from "src/services/prismic/templates";
import { graphql } from "gatsby";

const ProductsListing = ({
  data: { shopifyProducts: products, prismicData: pageData }
}) => {
  return (
    <Fragment>
      <SEO
        title={pageData.data?.title.text}
        description={pageData.data?.title.text}
        path="/"
      />
      <Layout>
        {pageData?.data?.body?.map((slice, idx) => {
          switch (slice.__typename) {
            case "PrismicPageBodyHero":
              return <HeroTemplate data={slice} key={idx} />;
            case "PrismicPageBodyShopPageListing":
              return (
                <ShopProductsList products={products?.edges} data={slice} />
              );
            default:
              return null;
          }
        })}
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query {
    prismicData: prismicPage(uid: { eq: "shop" }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PrismicHero
          ...ShopPageListing
        }
      }
    }
    shopifyProducts: allShopifyProduct {
      edges {
        node {
          id
          title
          description
          descriptionHtml
          totalInventory
          tags
          variants {
            id
            weight
            sku
            availableForSale
            inventoryQuantity
            selectedOptions {
              name
              value
            }
          }
          featuredImage {
            gatsbyImageData
            transformedSrc
          }
          images {
            id
            gatsbyImageData
          }
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
        }
      }
    }
  }
`;

export default ProductsListing;
